import {
  Middleware,
  MiddlewareAPI,
  configureStore,
  isRejectedWithValue,
} from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import rootReducer from '@redux/rootReducer';
import conversationApi from '@redux/api/conversationApiSlice';
import { onApiError } from '@redux/reducers/error.reducer';

const isDevServer = process.env.NODE_ENV === 'development';

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => next => action => {
    if (isRejectedWithValue(action)) {
      api.dispatch(onApiError(action.payload));
    }

    return next(action);
  };

const store = configureStore({
  reducer: rootReducer,
  devTools: isDevServer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(
      rtkQueryErrorLogger,
      conversationApi.middleware
    ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export default store;
