import { combineReducers } from 'redux';
import conversationApi from '@redux/api/conversationApiSlice';
import errorReducer from '@redux/reducers/error.reducer';

const rootReducer = combineReducers({
  errors: errorReducer,
  [conversationApi.reducerPath]: conversationApi.reducer,
});

export default rootReducer;
