const nl = {
  brands: {
    Adecco: 'Adecco',
    AdeccoFAQ: 'Adecco',
    AdeccoFull: 'Adecco',
    AdeccoGroup: 'The Adecco Group',
    AdeccoMedical: 'Adecco Medical',
    Amazon: 'Amazon',
    Akkodis: 'Akkodis',
    DISAG: 'DISAG',
    DISConsulting: 'DIS Consulting',
    EuropeAssistant: 'Europe Assistant',
    LHH: 'LHH',
    Modis: 'Modis',
    Pontoon: 'Pontoon',
    QAPA: 'QAPA',
  },
  candidateCapture: {
    intro:
      'Voordat u het gesprek begint, moet u uw contactgegevens aan ons doorgeven.',
    email: 'Email',
    firstname: 'Voornaam',
    lastname: 'Naam',
    countryCode: 'Selecteer landcode',
    phone: 'Telefoon',
    privacy:
      "Ik erken dat ik <a href={{privacyUrl}} target='_blank'>het privacybeleid</a> heb gelezen en geaccepteerd",
    termsAndPrivacy:
      "Ik erken dat ik <a href={{termsUrl}} target='_blank'>de gebruiksvoorwaarden</a> en <a href={{privacyUrl}} target='_blank'>het privacybeleid</a> heb gelezen en geaccepteerd.",
    submit: 'Voorleggen',
    validations: {
      emailRequired: 'Uw email is verplicht.',
      invalidEmail: 'Ongeldige e-mailindeling.',
      firstnameRequired: 'Uw voornaam is verplicht.',
      lastnameRequired: 'Je naam is verplicht.',
      phoneRequired: 'Uw telefoon is verplicht.',
      invalidPhone:
        'Et telefoonnummer moet beginnen met een landcode (+31XXXXXXXX)',
      termsAndCondRequired: 'Verplicht',
    },
  },
  common: {
    confirm: 'Bevestig',
    return: 'Terug',
    close: 'Sluiten',
    dateFormat: 'DD/MM/JJJJ',
    submit: 'Verstuur',
    pleaseWait: 'Even geduld a.u.b. terwijl we Career Assistant laden',
    hello: 'Hallo daar 👋',
    letsChat: 'laten we chatten',
    later: 'later',
    error: {
      title: 'Er is iets mis!',
      subtitle:
        'We kunnen geen verbinding maken met de service. Onze teams zijn op de hoogte gesteld en we werken eraan om het probleem snel op te lossen. Klik op Retry om het opnieuw te proberen of kom later terug.',
      retry: 'Opnieuw proberen',
    },
  },
  contact: {
    name: 'Naam',
    email: 'Email',
    description: 'Gelieve het probleem te beschrijven',
    submit: 'Verzenden',
    validations: {
      required: 'Dit veld is vereist.',
      invalidEmail: 'Ongeldige email.',
    },
    success: {
      title: 'Uw rapport is succesvol ingediend.',
      subtitle: 'U kunt dit venster nu sluiten.',
    },
  },
  contactPreference: {
    subscribe: 'Inschrijven',
    unsubscribe: 'Uitschrijven',
    contactPreference: 'Contact voorkeur',
    selectEmail: 'Selecteer uw voorkeuren voor e-mail',
    selectSms: 'Selecteer uw voorkeur voor SMS voor het nummer',
    selectWhatsApp: 'Selecteer je voorkeur voor WhatsApp voor het nummer',
    warning:
      'Als je "Afmelden" kiest, kun je niet langer e-mails en/of SMS- en/of WhatsApp-berichten van ons ontvangen.',
    warningEmail:
      'Door te kiezen voor "Uitschrijven" kunt u geen e-mails meer van ons ontvangen.',
    warningPhone:
      'Door te kiezen voor "Uitschrijven", zult u niet langer in staat zijn om sms\'jes van ons te ontvangen.',
    confirmed: 'Dank u, er is rekening gehouden met uw keuzes.',
    confirmedSubscribeEmail:
      'Dank u, uw registratieaanvraag per e-mail is gevalideerd.',
    confirmedSubscribePhone:
      'Dank u, uw registratieaanvraag per SMS is gevalideerd.',
    confirmedUnsubscribeEmail:
      'Dank u, uw verzoek om u uit te schrijven per e-mail is gevalideerd.',
    confirmedUnsubscribePhone:
      'Dank u, uw verzoek om u uit te schrijven per SMS is gevalideerd.',
    invalidEmail: 'De bevestigingsmail is niet correct.',
    invalidPhone:
      'De laatste 4 cijfers van de mobiele telefoon zijn niet correct.',
    invalidEmailOrPhone: 'De telefonische of e-mailbevestiging was onjuist.',
    confirmEmail: 'Bevestig je e-mailadres: ',
    confirmPhone: 'Typ de laatste 4 cijfers van uw mobiele telefoon in:',
    confirm: 'Bevestig',
    return: 'Terug',
  },
  interview: {
    interviewIntroduction:
      'Onze virtuele rekruteringsassistent, Career Assistant, heeft een paar extra vragen om je te pre-kwalificeren voor de functie. Het invullen van de vragen duurt minder dan 5 minuten en zal het aanwervingsproces versnellen, zodat we je zo snel mogelijk aan het werk kunnen zetten! Nadat je de vragen hebt beantwoord, kun je een afspraak maken bij de vestiging. Beantwoord de eerste vraag hieronder om te beginnen!',
    passMessage1: 'Fantastisch! U bent geslaagd voor de prekwalificatievragen!',
    passMessage2: 'Laten we naar de volgende stap gaan!',
    pleaseWait: 'Even geduld, de volgende stap komt eraan',
    changeInterviewSlot: 'Wijzig interview slot',
    rescheduleScheduled:
      'Uw interview op locatie is momenteel gepland voor {{ location }}.',
    chooseLocation: 'Kies de interviewlocatie van uw voorkeur.',
    chooseTime: 'Kies de gewenste interviewdatum en -tijd.',
    select: 'Selecteer',
    interviewDuration: 'Duur interview',
    noSlots: 'Helaas zijn er geen slots beschikbaar voor deze locatie.',
    rescheduled: 'Je hebt verplaatst!',
    scheduled: 'Je bent ingepland!',
    isRescheduled:
      'Uw nieuwe interview op locatie is nu gepland voor {{ location }}.',
    isScheduled: 'Uw interview op locatie is gepland voor {{ location }}.',
    yourInterviewDetails: 'Uw Interview Details',
    location: 'Locatie',
    dateAndTime: 'Datum en Tijd',
    sendingConfirmation:
      'We sturen je een bevestiging per e-mail. Als u de afspraak moet verzetten of annuleren, gebruik dan de onderstaande opties of volg de instructies in uw e-mail.',
    cancelInterview: 'Interview annuleren',
    ratingThankYou:
      'Bedankt voor je hulp bij het leveren van een betere gebruikerservaring.',
    ratingBeforeYouGo:
      'Kunt u, voordat u gaat, de ervaring van vandaag beoordelen?',
    ratingLeaveComment: 'Laat een opmerking achter',
    ratingLeaveCommentOptional: 'Laat een opmerking achter (optioneel)',
    ratingComment: 'Uw commentaar hoort hier...',
    ratingSubmit: 'Feedback verzenden',
    cancelAsk:
      'Het spijt ons te horen dat u uw interview wilt annuleren. Zou je willen overwegen om in plaats daarvan een nieuwe selectie te maken?',
    interviewCancelled: 'Interview geannuleerd',
    interviewCancelled1:
      'Je interview is geannuleerd. Je ontvangt binnenkort een bevestiging per e-mail. We vinden het jammer dat je weggaat.',
    interviewCancelled2:
      'Bezoek ons opnieuw om meer vacatures te zoeken of een nieuw interview te boeken.',
    error: {
      slotTaken:
        'Het spijt ons. Uw geselecteerde slot is zojuist bezet! Probeer opnieuw te selecteren',
      onverwacht:
        'Het spijt ons. Er is zojuist een onverwachte fout opgetreden.',
    },
    changeLocation: 'Selecteer een andere locatie',
    dateFull: '{{ date, full }}',
    dateDay: '{{ date, day }}',
    dateTime: '{{ date, time }}',
    timezone: '{{ date, timezone }}',
    interviewExpired:
      'U kunt dit interview niet langer verzetten of annuleren.',
  },
};

export default nl;
