const pl = {
  brands: {
    Adecco: 'Adecco',
    AdeccoFAQ: 'Adecco',
    AdeccoFull: 'Adecco',
    AdeccoGroup: 'The Adecco Group',
    AdeccoMedical: 'Adecco Medical',
    Amazon: 'Amazon',
    Akkodis: 'Akkodis',
    DISAG: 'DISAG',
    DISConsulting: 'DIS Consulting',
    EuropeAssistant: 'Europe Assistant',
    LHH: 'LHH',
    Modis: 'Modis',
    Pontoon: 'Pontoon',
    QAPA: 'QAPA',
  },
  candidateCapture: {
    intro:
      'Przed rozpoczęciem rozmowy prosimy o udostępnienie nam swoich danych kontaktowych.',
    email: 'Email',
    firstname: 'Imię',
    lastname: 'Nazwisko',
    countryCode: 'Wybierz kierunkowy kraju',
    phone: 'Numer telefonu',
    privacy:
      "Potwierdzam, że przeczytałem i akceptuję <a href={{privacyUrl}} target='_blank'>Politykę prywatności</a>.",
    termsAndPrivacy:
      "Potwierdzam, że przeczytałem i akceptuję <a href={{termsUrl}} target='_blank'>Warunki użytkowania</a> i <a href={{privacyUrl}} target='_blank'>Privacy Policy</a>.",
    submit: 'Wyślij',
    validations: {
      emailRequired: 'Wymagany jest adres e-mail.',
      invalidEmail: 'Format adresu e-mail jest nieprawidłowy.',
      firstnameRequired: 'Imię jest wymagane.',
      lastnameRequired: 'Nazwisko jest wymagane.',
      phoneRequired: 'Numer telefonu jest wymagany.',
      invalidPhone:
        'Numer telefonu powinien zaczynać się od "+" i numeru kierunkowego kraju, np. +48xxxxxxxx.',
      termsAndCondRequired: 'Wymagane',
    },
  },
  common: {
    confirm: 'potwierdzać',
    return: 'Powrót',
    close: 'Zamknij',
    dateFormat: 'DD/MM/RRRR',
    submit: 'Prześlij',
    pleaseWait: 'Poczekaj na załadowanie aplikacji Career Assistant',
    hello: 'Cześć 👋',
    letsChat: 'porozmawiajmy',
    later: 'później',
    error: {
      title: 'Coś jest nie tak!',
      subtitle:
        'Nie możemy połączyć się z usługą. Nasze zespoły zostały powiadomione i pracujemy nad szybkim naprawieniem problemu. Kliknij przycisk Spróbuj ponownie, aby spróbować ponownie lub wróć później.',
      retry: 'Powrót',
    },
  },
  contact: {
    name: 'Nazwa',
    email: 'E-mail',
    description: 'Prosimy o opisanie problemu',
    submit: 'Prześlij',
    validations: {
      required: 'To pole jest wymagane',
      invalidEmail: 'Nieprawidłowy e-mail.',
    },
    success: {
      title: 'Twój raport został pomyślnie przesłany',
      subtitle: 'Możesz teraz zamknąć to okno.',
    },
  },
  contactPreference: {
    subscribe: 'subskrybuj',
    unsubscribe: 'Zrezygnuj z subskrypcji',
    contactPreference: 'Preferencje dotyczące kontaktu',
    selectEmail: 'Wybierz prefererencje dla adresu e-mail',
    selectSms: 'Wybierz preferencje dotyczące wiadomości SMS dla numeru',
    selectWhatsApp: 'Wybierz preferowaną opcję WhatsApp dla numeru',
    warning:
      'Wybierając opcję "Anuluj subskrypcję", użytkownik nie będzie już mógł otrzymywać od nas wiadomości e-mail i/lub wiadomości SMS i/lub wiadomości WhatsApp.',
    warningEmail:
      'Wybierając opcję "Anuluj subskrypcję", nie będziesz już mógł otrzymywać od nas wiadomości e-mail.',
    warningPhone:
      'Wybierając opcję "Anuluj subskrypcję", nie będziesz już otrzymywać od nas wiadomości SMS.',
    confirmed: 'Dziękujemy, Twoje wybory zostały uwzględnione.',
    confirmedSubscribeEmail:
      'Dziękujemy, Twoja prośba o rejestrację przez e-mail została zatwierdzona.',
    confirmedSubscribePhone:
      'Dziękujemy, Twoja prośba o rejestrację przez SMS została zweryfikowana.',
    confirmedUnsubscribeEmail:
      'Dziękujemy, Twoja prośba o anulowanie subskrypcji przez e-mail została zweryfikowana.',
    confirmedUnsubscribePhone:
      'Dziękujemy, Twoja prośba o anulowanie subskrypcji przez SMS została zweryfikowana.',
    invalidEmail: 'Podany potwierdzajacy adres e-mail jest nieprawidłowy.',
    invalidPhone: 'Ostatnie 4 cyfry telefonu komórkowego są nieprawidłowe.',
    invalidEmailOrPhone:
      'Potwierdzenie telefoniczne lub e-mailowe było nieprawidłowe.',
    confirmEmail: 'Potwierdź swój adres e-mail: ',
    confirmPhone: 'Wpisz ostatnie 4 cyfry swojego telefonu komórkowego: ',
    confirm: 'potwierdzać',
    return: 'Powrót',
  },
  interview: {
    interviewIntroduction:
      'Nasz wirtualny asystent ds. rekrutacji, Career Assistant, ma kilka dodatkowych pytań, aby wstępnie zakwalifikować cię na dane stanowisko. Wypełnienie tych pytań zajmie mniej niż 5 minut i przyspieszy proces rekrutacji, umożliwiając nam jak najszybsze rozpoczęcie pracy! Po udzieleniu odpowiedzi na pytania będziesz mógł zaplanować spotkanie w oddziale. Odpowiedz na pierwsze pytanie poniżej, aby rozpocząć!',
    passMessage1: 'Fantastycznie! Przeszedłeś wstępne pytania kwalifikacyjne!',
    passMessage2: 'Przejdźmy do następnego kroku!',
    pleaseWait: 'Prosimy czekac, następny krok niedlugo nastąpi',
    changeInterviewSlot: 'Zmiana miejsca na rozmowę kwalifikacyjną',
    rescheduleScheduled:
      'Rozmowa kwalifikacyjna na miejscu jest obecnie zaplanowana na {{ location }}.',
    chooseLocation:
      'Wybierz preferowaną lokalizację rozmowy kwalifikacyjnej na miejscu',
    chooseTime: 'Wybierz preferowaną datę i godzinę rozmowy kwalifikacyjnej',
    select: 'Wybierz',
    interviewDuration: 'Czas trwania rozmowy kwalifikacyjnej',
    noSlots: 'Niestety, nie ma dostępnych terminów dla tej lokalizacji.',
    rescheduled: 'Zmiana terminu!',
    scheduled: 'Jesteś umówiony!',
    isRescheduled:
      'Twoja nowa rozmowa kwalifikacyjna na miejscu jest teraz zaplanowana na {{ lokalizacja }}.',
    isScheduled:
      'Twoja rozmowa kwalifikacyjna na miejscu jest zaplanowana na {{ lokalizacja }}.',
    yourInterviewDetails: 'Szczegóły rozmowy kwalifikacyjnej',
    location: 'Lokalizacja',
    dateAndTime: 'Data i godzina',
    sendingConfirmation:
      'Wyślemy Ci potwierdzenie e-mailem. Jeśli chcesz przełożyć lub anulować spotkanie, skorzystaj z poniższych opcji lub postępuj zgodnie z instrukcjami w wiadomości e-mail.',
    cancelInterview: 'Anuluj rozmowę kwalifikacyjną',
    ratingThankYou:
      'Dziękujemy za pomoc w zapewnieniu lepszej obsługi użytkownika.',
    ratingBeforeYouGo:
      'Zanim odejdziesz, czy możesz ocenić dzisiejsze doświadczenie?',
    ratingLeaveComment: 'Zostaw nam komentarz',
    ratingLeaveCommentOptional: 'Zostaw nam komentarz (opcjonalnie)',
    ratingComment: 'Twój komentarz jest tutaj...',
    ratingSubmit: 'Wyślij opinię',
    cancelAsk:
      'Przykro nam słyszeć, że chcesz anulować rozmowę kwalifikacyjną. Czy rozważyłbyś zamiast tego dokonanie nowego wyboru?',
    interviewCancelled: 'Rozmowa kwalifikacyjna odwołana',
    interviewCancelled1:
      'Twoja rozmowa kwalifikacyjna została anulowana. Wkrótce otrzymasz potwierdzenie e-mailem. Przykro nam, że musisz odejść.',
    interviewCancelled2:
      'Odwiedź nas ponownie, aby wyszukać więcej ofert pracy lub zarezerwować nową rozmowę kwalifikacyjną.',
    error: {
      slotTaken:
        'Przepraszamy. Wybrany termin został właśnie zajęty! Spróbuj wybrać ponownie.',
      unexpected: 'Przepraszamy. Wystąpił nieoczekiwany błąd.',
    },
    changeLocation: 'Wybierz inną lokalizację',
    dateFull: '{{ date, full }}',
    dateDay: '{{ date, day }}',
    dateTime: '{{ date, time }}',
    timezone: '{{ data, timezone }}',
    interviewExpired:
      'Nie można już przełożyć ani anulować tej rozmowy kwalifikacyjnej.',
  },
};

export default pl;
