import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const errorHandlerSlice = createSlice({
  name: 'errorHandler',
  initialState: {
    apiError: null,
  },
  reducers: {
    onApiError: (state, action: PayloadAction<string>) => {
      state.apiError = action.payload;
    },
  },
});

export const { onApiError } = errorHandlerSlice.actions;

export default errorHandlerSlice.reducer;
