import ReactDOM from 'react-dom/client';
import App from './App';
import './i18n/i18n';
import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('adecco.ca.cxbot'));

root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);
