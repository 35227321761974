const es = {
  brands: {
    Adecco: 'Adecco',
    AdeccoFAQ: 'Adecco',
    AdeccoFull: 'Adecco',
    AdeccoGroup: 'The Adecco Group',
    AdeccoMedical: 'Adecco Medical',
    Amazon: 'Amazon',
    Akkodis: 'Akkodis',
    DISAG: 'DISAG',
    DISConsulting: 'DIS Consulting',
    EuropeAssistant: 'Europe Assistant',
    LHH: 'LHH',
    Modis: 'Modis',
    Pontoon: 'Pontoon',
    QAPA: 'QAPA',
  },
  candidateCapture: {
    intro:
      'Antes de iniciar la conversación, por favor, comparte con nosotros tus datos de contacto',
    email: 'Correo electrónico',
    firstname: 'Nombre',
    lastname: 'Apellido',
    countryCode: 'Seleccionar código de país',
    phone: 'Teléfono',
    privacy:
      "Reconozco que he leído y que acepto <a href={{privacyUrl}} target='_blank'>la Política de privacidad</a>.",
    termsAndPrivacy:
      "Reconozco que he leído y que acepto <a href={{termsUrl}} target='_blank'>los Términos de uso</a> y <a href={{privacyUrl}} target='_blank'>la Política de privacidad</a>.",
    submit: 'Enviar',
    validations: {
      emailRequired: 'El correo electrónico es obligatorio.',
      invalidEmail: 'El formato del correo electrónico no es válido.',
      firstnameRequired: 'Nombre es obligatorio.',
      lastnameRequired: 'Apellido es obligatorio.',
      phoneRequired: 'Teléfono es obligatorio.',
      invalidPhone:
        'El número de teléfono debe empezar por "+" y tu código de país (ej: +34xxxxxxxxx)',
      termsAndCondRequired: 'Obligatorio',
    },
  },
  common: {
    confirm: 'Confirmar',
    return: 'Volver',
    close: 'Cerrar',
    dateFormat: 'DD/MM/YYYY',
    submit: 'Enviar',
    pleaseWait: 'Por favor, espere mientras cargamos Career Assistant',
    hello: 'Hola 👋',
    letsChat: 'hablemos',
    later: 'más tarde',
    error: {
      title: '¡Algo va mal!',
      subtitle:
        'No podemos conectarnos al servicio. Nuestros equipos han sido notificados y estamos trabajando para solucionar rápidamente el problema. Haz clic en Reintentar para volver a intentarlo o vuelve más tarde.',
      retry: 'Reintentar',
    },
  },
  contact: {
    name: 'Nombre',
    email: 'Email',
    description: 'Por favor, describe el problema',
    submit: 'Enviar',
    validations: {
      required: 'Este campo es obligatorio.',
      invalidEmail: 'Email no válido.',
    },
    success: {
      title: 'Su informe se ha enviado correctamente.',
      subtitle: 'Ya puede cerrar esta ventana.',
    },
  },
  contactPreference: {
    subscribe: 'Suscribirse',
    unsubscribe: 'Darse de baja',
    contactPreference: 'Preferencia de contacto',
    selectEmail:
      'Nos da pena perderte. Recuerda que muchas novedades y oportunidades de Adecco sólo se comunican por correo. Por favor, confirma si quieres dar de baja el correo ',
    selectSms:
      'Nos da pena perderte. Recuerda que muchas novedades y oportunidades de Adecco sólo se comunican por SMS. Por favor, confirma si quieres dar de baja el número de teléfono ',
    selectWhatsApp:
      'Nos da pena perderte. Recuerda que muchas novedades y oportunidades de Adecco sólo se comunican por WhatsApp. Por favor, confirma si quieres dar de baja el número de teléfono ',
    warning:
      'Al elegir «Darse de baja», ya no podrás recibir comunicaciones por SMS y/o correo de Adecco Career Assistant.',
    warningEmail:
      'Al elegir «Darse de baja», ya no podrás recibir comunicaciones por correo de Adecco Career Assistant.',
    warningPhone:
      'Al elegir «Darse de baja», ya no podrás recibir comunicaciones por SMS de Adecco Career Assistant.',
    warningWhatsApp:
      'Al elegir «Darse de baja», ya no podrás recibir comunicaciones por WhatsApp de Adecco Career Assistant.',
    confirmed: 'Gracias, sus elecciones se han tenido en cuenta.',
    confirmedSubscribeEmail:
      'Gracias, su solicitud de registro por correo electrónico ha sido validada.',
    confirmedSubscribePhone:
      'Gracias, su solicitud de registro por SMS ha sido validada.',
    confirmedUnsubscribeEmail:
      'Gracias, su solicitud de cancelación de la suscripción por correo electrónico ha sido validada.',
    confirmedUnsubscribePhone:
      'Gracias, su solicitud de cancelación de la suscripción por SMS ha sido validada.',
    invalidEmail: 'El E-mail de confirmación no es correcto',
    invalidPhone: 'Las cuatro últimas cifras del teléfono no coinciden.',
    invalidEmailOrPhone:
      'El teléfono y/o el E-mail introducidos son incorrectos.',
    confirmEmail: 'Por favor confirme su E-mail: ',
    confirmPhone:
      'Por favor confirme las 4 últimas cifras de su teléfono móvil: ',
    confirm: 'Confirmar',
    return: 'Volver',
  },
  interview: {
    interviewIntroduction:
      'Nuestro asistente virtual de contratación, Career Assistant, tiene algunas preguntas adicionales para precalificarte para el puesto. Las preguntas tardan menos de 5 minutos en completarse y acelerarán el proceso de contratación, ¡permitiéndonos ofrecerle trabajo lo antes posible! Después de responder a las preguntas, podrá seguir adelante y concertar una entrevista. Responde a la primera pregunta a continuación para empezar!',
    passMessage1: '¡Fantástico! Has superado las preguntas de precalificación!',
    passMessage2: '¡Pasemos al siguiente paso!',
    pleaseWait: 'Por favor, espere, el siguiente paso está en camino',
    changeInterviewSlot: 'Cambiar horario de entrevista',
    rescheduleScheduled:
      'Su entrevista presencial está programada actualmente para {{ location }}',
    chooseLocation:
      'Elija la ubicación presencial preferida para la entrevista',
    chooseTime: 'Elija la fecha y hora que prefiera para la entrevista',
    select: 'Seleccione',
    interviewDuration: 'Duración de la entrevista',
    noSlots: 'Lamentablemente, no hay citas disponibles para esta ubicación',
    rescheduled: '¡Se ha reprogramado la entrevista correctamente!',
    scheduled: '¡Se ha programado la entrevista correctamente!',
    isRescheduled:
      'Su nueva entrevista presencial está programada para {{ location }}',
    isScheduled: 'Su entrevista presencial está programada para {{ location }}',
    yourInterviewDetails: 'SusDetallesDeLaEntrevista',
    location: 'Ubicación',
    dateAndTime: 'Fecha y hora',
    sendingConfirmation:
      'Le enviaremos una confirmación por correo electrónico. Si necesita reprogramar o cancelar, utilice las opciones a continuación o siga las instrucciones del correo electrónico.',
    cancelInterview: 'Cancelar entrevista',
    ratingThankYou:
      'Gracias por ayudarnos a ofrecer una mejor experiencia de usuario',
    ratingBeforeYouGo: 'Antes de irte, ¿puedes valorar la experiencia de hoy?',
    ratingLeaveComment: 'Déjanos un comentario',
    ratingLeaveCommentOptional: 'Déjanos un comentario (opcional)',
    ratingComment: 'Tu comentario va aquí...',
    ratingSubmit: 'Enviar comentario',
    cancelAsk:
      'Lamentamos saber que desea cancelar su entrevista. ¿Considerarías hacer una nueva selección en su lugar?',
    interviewCancelled: 'EntrevistaCancelada',
    interviewCancelled1:
      'Su entrevista ha sido cancelada. En breve te enviaremos un correo electrónico de confirmación. Lamentamos tu marcha',
    interviewCancelled2:
      'Por favor, visítanos de nuevo para buscar más empleos o reservar una nueva entrevista',
    error: {
      slotTaken:
        'Lo sentimos. La vacante que has seleccionado está ocupada. Por favor, intente seleccionar de nuevo',
      unexpected: 'Lo sentimos. Se ha producido un error inesperado',
    },
    changeLocation: 'Seleccione otra ubicación',
    dateFull: '{{ date, full }}',
    dateDay: '{{ date, day }}',
    dateTime: '{{ date, time }}',
    timezone: '{{ date, timezone }}',
    interviewExpired: 'Ya no puede reprogramar ni cancelar esta entrevista',
  },
};

export default es;
