const ja = {
  brands: {
    Adecco: 'Adecco',
    AdeccoFAQ: 'Adecco',
    AdeccoFull: 'Adecco',
    AdeccoGroup: 'The Adecco Group',
    AdeccoMedical: 'Adecco Medical',
    Amazon: 'Amazon',
    Akkodis: 'Akkodis',
    DISAG: 'DISAG',
    DISConsulting: 'DIS Consulting',
    EuropeAssistant: 'Europe Assistant',
    LHH: 'LHH',
    Modis: 'Modis',
    Pontoon: 'Pontoon',
    QAPA: 'QAPA',
  },
  candidateCapture: {
    intro: '「会話を始める前に、あなたの連絡先を教えてください',
    email: '電子メール',
    firstname: '名',
    lastname: '姓',
    countryCode: '国コードを選択してください',
    phone: '電話番号',
    privacy:
      "私は<a href={{privacyUrl}} target='_blank'>プライバシーポリシー</a>に同意します。",
    termsAndPrivacy:
      "私は<a href={{termsUrl}} target='_blank'>利用規約</a> と <a href={{privacyUrl}} target='_blank'>プライバシーポリシー</a>に同意します。",
    submit: '送信',
    validations: {
      emailRequired: '電子メール が必要です。',
      invalidEmail: '電子メールの形式が無効です',
      firstnameRequired: '名は必須です。',
      lastnameRequired: '姓は必須です。',
      phoneRequired: '電話番号の入力が必要です。',
      invalidPhone:
        '電話番号は、"+"で始まる番号と国番号（例：+81xxxxxxx) を入力してください。',
      termsAndCondRequired: '必須',
    },
  },
  common: {
    confirm: '実行',
    return: '戻る',
    close: '閉じる',
    dateFormat: '日/月/年',
    submit: '送信',
    pleaseWait: 'Career Assistantを読み込むまでお待ちください',
    hello: 'こんにちは👋',
    letsChat: 'チャットしよう',
    later: '後で',
    error: {
      title: 'エラーが発生しました',
      subtitle:
        'サービスに接続することができません。再試行をクリックしてもう一度試すか、後でもう一度お試しください。',
      retry: '再試行',
    },
  },
  contact: {
    name: '名称',
    email: '電子メール',
    description: 'エラーの内容を説明してください',
    submit: '送信',
    validations: {
      required: 'この項目は必須です。',
      invalidEmail: '無効なメールです。',
    },
    success: {
      title: 'レポートが正常に送信されました。',
      subtitle: 'このウィンドウを閉じることができます。',
    },
  },
  contactPreference: {
    subscribe: '受信する',
    unsubscribe: '配信停止',
    contactPreference: '連絡先設定',
    selectEmail: '連絡先メールアドレスを選択してください。',
    selectSms: 'ご希望のSMS番号を選択してください。',
    selectWhatsApp: 'ご希望のWhatsApp番号をお選びください。',
    warning:
      '"配信停止"」を選択すると、当社からのEメールおよび/またはSMSおよび/またはWhatsAppメッセージが届かなくなります。',
    warningEmail:
      '"配信停止"を選択することで、当社からのメールを受信できなくなります。',
    warningPhone:
      '"配信停止"を選択することで、当社からのSMSを受信できなくなります。',
    confirmed: '選択内容が登録されました。',
    confirmedSubscribeEmail: 'メール配信の登録申請を承りました。',
    confirmedSubscribePhone: 'SMS配信の登録申請を承りました。',
    confirmedUnsubscribeEmail: 'メール配信の停止を承りました。',
    confirmedUnsubscribePhone: 'SMS配信の停止を承りました。',
    invalidEmail: '入力された確認用メールアドレスが正しくありません。',
    invalidPhone: '携帯電話番号の下4桁の数字が正しくありません。',
    invalidEmailOrPhone: '電話番号またはメールアドレスが正しくありません。',
    confirmEmail: 'メールアドレスをご確認ください: ',
    confirmPhone: '携帯電話番号の下4桁を入力してください: ',
    confirm: '実行',
    return: '戻る',
  },
  interview: {
    interviewIntroduction:
      '当社のバーチャル採用アシスタントであるCareer Assistantが、あなたをこのポジションにふさわしい人物と事前に判断するために、追加質問を用意しています。所要時間は5分です。さっそく始めましょう！',
    passMessage1: '素晴らしいです！あなたは事前確認の質問に合格しました。',
    passMessage2: '次のステップに進みましょう。',
    pleaseWait: '次のステップに進みますのでお待ちください。',
    changeInterviewSlot: '面接枠を変更してください',
    rescheduleScheduled: 'あなたの面接は現在{{location}}で予定されています。',
    chooseLocation: 'ご希望の面接場所をお選びください。',
    chooseTime: 'ご希望の面接日時をお選びください',
    select: '選択する',
    interviewDuration: '面接時間',
    noSlots: '残念ながら、この場所では空き枠がありません。',
    rescheduled: '再スケジュールされました。',
    scheduled: '予約が完了しました',
    isRescheduled: 'あなたの新しい面接場所は{{location}}に設定されました。',
    isScheduled: 'あなたの面接は{{location}}で予定されています。',
    yourInterviewDetails: 'あなたの面接の詳細',
    location: '場所',
    dateAndTime: '日時',
    sendingConfirmation:
      '確認メールを送信させていただきます。日程変更またはキャンセルが必要な場合は、以下のオプションを使用するか、メールの指示に従ってください。',
    cancelInterview: '面接をキャンセルする',
    ratingThankYou:
      'より良いユーザー体験を提供するためにご協力いただきありがとうございます。',
    ratingBeforeYouGo: '最後に、本日の体験を評価していただけますか？',
    ratingLeaveComment: 'コメントを残してください',
    ratingLeaveCommentOptional: 'コメントを残してください（任意）',
    ratingComment: 'コメントはこちら...',
    ratingSubmit: 'フィードバックを送信する',
    cancelAsk:
      '面接をキャンセルしたいとのことですが、代わりに新しい空き枠を選択しますか？',
    interviewCancelled: '面接がキャンセルされました',
    interviewCancelled1:
      'あなたの面接はキャンセルされました。間もなく確認のメールが送信されます。',
    interviewCancelled2:
      'お仕事を検索したり、面接予約をするために、またぜひ当社のサービスをご利用ください。',
    error: {
      slotTaken:
        '申し訳ございません。選択された枠はちょうど埋まってしまいました。もう一度選択してみてください',
      unexpected: '申し訳ございません。予期せぬエラーが発生しました。',
    },
    changeLocation: '別の場所を選択してください',
    dateFull: '{{ date, full }}',
    dateDay: '{{ date, day }}',
    dateTime: '{{ date, time }}',
    timezone: '{{ date, timezone }}',
    interviewExpired: 'この面接の再スケジュールやキャンセルはもうできません。',
  },
};

export default ja;
