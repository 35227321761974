import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { v4 as uuid } from 'uuid';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { ConnectionStatus, DirectLine } from 'botframework-directlinejs';
import { ConversationType, DirectLineModel } from '@adeccoca/cxbot-core';
import {
  useCaptureCandidateMutation,
  useLazyGetBotTokenQuery,
  useRegisterClickMutation,
} from '@redux/api/conversationApiSlice';
import Loader from '@common/Loader';
import { CandidateCaptureModel } from '@appTypes/conversation.types';
import { addToLocalStorage, retrieveFromLocalStorage } from '@common/utils';

interface IDirectLineContext {
  shortener: string;
  directline: DirectLine;
  model: DirectLineModel | CandidateCaptureModel;
  initializeDirectLine: () => void;
}

export const DirectLineContext = React.createContext<
  IDirectLineContext | undefined
>(undefined);

export const DirectLineContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [model, setModel] = useState<DirectLineModel | CandidateCaptureModel>(
    null
  );
  const [directline, setDirectline] = useState<DirectLine>(null);
  const params = useParams();
  const [getBotToken] = useLazyGetBotTokenQuery();
  const [captureCandidate] = useCaptureCandidateMutation();
  const [registerClick] = useRegisterClickMutation();

  const shortener = params.shortener || (window as any)?.CACxBotOptions?.id;
  const initializeDirectLine = useCallback(async () => {

    const { data: model } = await getBotToken(shortener);
    setModel(model);

    if (model?.type === ConversationType.CANDIDATECAPTURE) {
      if (retrieveFromLocalStorage(`${model.campaignId}_code`)) {
        return navigate(
          `/${retrieveFromLocalStorage(`${model.campaignId}_code`)}`
        );
      }

      if (!retrieveFromLocalStorage(`${model.campaignId}_seen`)) {
        const candidateId = retrieveFromLocalStorage('candidate_id') || uuid();
        const formData = new FormData();
        formData.set('candidateID', candidateId);
        formData.set('campaignId', model.campaignId);

        const source = searchParams.get('s');
        if (source) {
          formData.set('source', source);
        }

        await registerClick(formData);
        addToLocalStorage('candidate_id', candidateId);
        addToLocalStorage(`${model?.campaignId}_seen`, '1');
      }

      if ((model as CandidateCaptureModel)?.formless) {
        const formData = new FormData();

        formData.set('JSON', '1');
        formData.set('CampaignId', model?.campaignId);
        formData.set('Brand', model?.brand);
        const { success, code } = await captureCandidate(formData).unwrap();

        if (success) {
          addToLocalStorage(`${model?.campaignId}_code`, code);
          if ((window as any)?.CACxBotOptions?.isEmbed) {
            (window as any).CACxBotOptions.id = shortener;
          }
          return navigate(`/${code}`);
        } else {
          return;
        }
      }
      return navigate(`/candidateCapture/${shortener}`);
    }

    const directLineInstance = new DirectLine({
      secret: (model as DirectLineModel)?.botToken,
      conversationId: (model as DirectLineModel)?.conversationId,
      webSocket: true,
      domain: window?.location?.hostname?.includes('localhost')
        ? 'http://localhost:8008/directline'
        : undefined,
    });
    setDirectline(directLineInstance);
  }, [
    captureCandidate,
    getBotToken,
    navigate,
    registerClick,
    searchParams,
    shortener,
  ]);

  useEffect(() => {
    try {
      directline?.connectionStatus$.subscribe(status => {
        if (
          status === ConnectionStatus.ExpiredToken ||
          status === ConnectionStatus.FailedToConnect ||
          status === ConnectionStatus.Ended
        ) {
          if (!(window as any).CACxBotOptions?.isEmbed) {
            window.location.reload();
          }
        }
      });
    } catch (err: unknown) {}
  }, [
    directline,
    directline?.activity$,
    directline?.connectionStatus$,
    initializeDirectLine,
    model?.botToken,
  ]);

  const value = useMemo(
    () => ({ shortener, directline, model, initializeDirectLine }),
    [directline, initializeDirectLine, model, shortener]
  );

  return (
    <DirectLineContext.Provider value={value}>
      {children}
    </DirectLineContext.Provider>
  );
};

export const useDirectLineContext = () => {
  const directLineContext = useContext(DirectLineContext);
  if (directLineContext === undefined) {
    throw new Error(
      'useDirectLineContext must be used inside DirectLineContextProvider'
    );
  }

  return directLineContext;
};
