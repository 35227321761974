const de = {
  brands: {
    Adecco: 'Adecco',
    AdeccoFAQ: 'Adecco',
    AdeccoFull: 'Adecco',
    AdeccoGroup: 'The Adecco Group',
    AdeccoMedical: 'Adecco Medical',
    Amazon: 'Amazon',
    Akkodis: 'Akkodis',
    DISAG: 'DISAG',
    DISConsulting: 'DIS Consulting',
    EuropeAssistant: 'Europe Assistant',
    LHH: 'LHH',
    Modis: 'Modis',
    Pontoon: 'Pontoon',
    QAPA: 'QAPA',
  },
  candidateCapture: {
    intro:
      'Bevor Sie mit dem Gespräch beginnen, teilen Sie uns bitte Ihre Kontaktdaten mit.',
    email: 'E-mail',
    firstname: 'Vorname',
    lastname: 'Nachname',
    countryCode: 'Wählen Sie den Ländercode',
    phone: 'Telefon',
    privacy:
      "Ich bestätige, dass ich <a href={{privacyUrl}} target='_blank'>die Datenschutzbestimmungen</a> gelesen und akzeptiert habe.",
    termsAndPrivacy:
      "Ich bestätige, dass ich <a href={{termsUrl}} target='_blank'>die Nutzungsbedingungen</a> und <a href={{privacyUrl}} target='_blank'>die Datenschutzbestimmungen</a> gelesen und akzeptiert habe.",
    submit: 'Senden',
    validations: {
      emailRequired: 'E-Mail ist erforderlich.',
      invalidEmail: 'Ungültiges E-Mail-Format.',
      firstnameRequired: 'Vorname ist erforderlich.',
      lastnameRequired: 'Nachname ist erforderlich.',
      phoneRequired: 'Eine Telefonnummer ist erforderlich.',
      invalidPhone:
        'Die Telefonnummer muss mit einer Landesvorwahl beginnen (+49XXXXXXXX)',
      termsAndCondRequired: 'Erforderlich',
    },
  },
  common: {
    confirm: 'Bestätigen',
    return: 'Zurück',
    close: 'Schließen',
    dateFormat: 'TT/MM/JJJJ',
    submit: 'Senden',
    pleaseWait: 'Bitte warten Sie, während der Career Assistant lädt',
    hello: 'Hallo 👋',
    letsChat: 'lass uns chatten',
    later: 'später',
    error: {
      title: 'Etwas hat nicht klappt!',
      subtitle:
        'Wir können keine Verbindung zu dem Dienst herstellen. Unser Team wurde benachrichtigt und wir arbeiten daran, das Problem schnell zu beheben. Klicken Sie auf Wiederholen, um es erneut zu versuchen oder schauen Sie später wieder vorbei.',
      retry: 'Erneut versuchen',
    },
  },
  contact: {
    name: 'Name',
    email: 'E-mail',
    description: 'Bitte beschreiben Sie das Problem',
    submit: 'Senden',
    validations: {
      required: 'Dieses Feld ist erforderlich.',
      invalidEmail: 'Ungültige E-mail-Adresse.',
    },
    success: {
      title: 'Ihre Nachricht wurde erfolgreich gesendet.',
      subtitle: 'Sie können dieses Fenster jetzt schließen.',
    },
  },
  contactPreference: {
    subscribe: 'Registrieren',
    unsubscribe: 'Abbestellen',
    contactPreference: 'Kontaktpräferenz',
    selectEmail: 'Wählen Sie die Präferenzen für Ihre E-mail',
    selectSms: 'Wählen Sie die Präferenzen für SMS für die Nummer',
    selectWhatsApp: 'Wählen Sie die Präferenzen für WhatsApp für die Nummer',
    warning:
      'Wenn Sie "Abmelden" wählen, können Sie keine E-Mails und/oder SMS und/oder WhatsApp-Nachrichten mehr von uns erhalten.',
    warningEmail:
      'Wenn Sie "Abbestellen" wählen, können Sie keine E-Mails mehr von uns erhalten.',
    warningPhone:
      'Wenn Sie "Abmelden" wählen, können Sie keine SMS-Nachrichten mehr von uns erhalten.',
    confirmed: 'Danke, Ihre Entscheidungen wurden berücksichtigt.',
    confirmedSubscribeEmail:
      'Vielen Dank, Ihre Registrierungsanfrage per E-Mail wurde validiert.',
    confirmedSubscribePhone:
      'Vielen Dank, Ihre Registrierungsanfrage per SMS wurde validiert.',
    confirmedUnsubscribeEmail:
      'Vielen Dank, Ihre Anfrage zur Abmeldung per E-Mail wurde bestätigt.',
    confirmedUnsubscribePhone:
      'Vielen Dank, Ihre Anfrage zur Abmeldung per SMS wurde bestätigt.',
    invalidEmail: 'Die E-mail-Adresse zur Bestätigung ist falsch.',
    invalidPhone: 'Die Telefonnummer zur Bestätigung ist falsch',
    invalidEmailOrPhone:
      'Die Telefonnummer oder die E-mail-Addresse zur Bestätigung sind falsch.',
    confirmEmail: 'Bitte bestätigen Sie Ihre E-mail-Addresse: ',
    confirmPhone:
      'Bitte bestätigen Sie die letzte 4 Ziffern Ihrer Handynummer: ',
    confirm: 'Bestätigen',
    return: 'Zurück',
  },
  interview: {
    interviewIntroduction:
      'Unser virtueller Recruiting-Chatbot, Career Assistant, hat ein paar Fragen, um Sie für die Stelle zu qualifizieren. Das Ausfüllen der Fragen dauert weniger als 5 Minuten und beschleunigt den Einstellungsprozess, so dass wir Sie so schnell wie möglich einstellen können. Nachdem Sie die Fragen beantwortet haben, können Sie ein Interview an einer unserer Niederlassungen vereinbaren. Beantworten Sie die erste Frage, um loszulegen.',
    passMessage1: 'Fantastisch! Sie haben die Qualifikationsfragen bestanden!',
    passMessage2: 'Gehen wir zum nächsten Schritt über!',
    pleaseWait: 'Bitte warten Sie, der nächste Schritt wird gerade geladen',
    changeInterviewSlot: 'Interview-Termin ändern',
    rescheduleScheduled:
      'Ihr Vorstellungsgespräch ist derzeit für {{ location }} geplant.',
    chooseLocation:
      'Bitte wählen Sie Ihren bevorzugten Ort für das Vorstellungsgespräch aus.',
    chooseTime:
      'Bitte wählen Sie Ihr bevorzugtes Datum und Ihre bevorzugte Uhrzeit für das Vorstellungsgespräch.',
    select: 'Wählen',
    interviewDuration: 'Dauer des Vorstellungsgesprächs',
    noSlots: 'Leider sind für diesen Ort keine Termine verfügbar.',
    rescheduled: 'Sie haben den Termin verschoben.',
    scheduled: 'Sie haben den Termin gebucht.',
    isRescheduled:
      'Ihr neues Vorstellungsgespräch vor Ort ist jetzt für {{ location }} geplant.',
    isScheduled:
      'Ihr Vorstellungsgespräch vor Ort ist für {{ location }} geplant.',
    yourInterviewDetails: 'Ihre Interviewdetails',
    location: 'Ort',
    dateAndTime: 'Datum und Uhrzeit',
    sendingConfirmation:
      'Wir werden Ihnen eine Bestätigung per E-Mail senden. Wenn Sie den Termin verschieben oder absagen müssen, nutzen Sie bitte die unten stehenden Optionen oder folgen Sie den Anweisungen in Ihrer E-Mail.',
    cancelInterview: 'Vorstellungsgespräch absagen',
    ratingThankYou:
      'Vielen Dank, dass Sie uns helfen, das Produkt zu verbessern.',
    ratingBeforeYouGo:
      'Bevor Sie das Gespräch verlassen, können Sie noch Ihre Erfahrung mit dem Chatbot bewerten?',
    ratingLeaveComment: 'Hinterlassen Sie uns gerne einen Kommentar',
    ratingLeaveCommentOptional:
      'Hinterlassen Sie uns gerne einen Kommentar (optional)',
    ratingComment: 'Ihr Kommentar kommt hier hin...',
    ratingSubmit: 'Feedback senden',
    cancelAsk:
      'Es tut uns leid zu hören, dass Sie Ihr Vorstellungsgespräch absagen möchten. Würden Sie stattdessen eine neue Auswahl treffen?',
    interviewCancelled: 'Vorstellungsgespräch abgesagt',
    interviewCancelled1:
      'Ihr Vorstellungsgespräch wurde abgesagt. Sie erhalten in Kürze eine Bestätigung per E-Mail. Wir bedauern, dass Sie uns absagen.',
    interviewCancelled2:
      'Bitte besuchen Sie uns erneut, um nach weiteren Stellen zu suchen oder ein neues Vorstellungsgespräch zu vereinbaren.',
    error: {
      slotTaken:
        'Es tut uns leid. Ihr ausgewählter Termin wurde gerade besetzt! Bitte versuchen Sie, einen anderen Termin zu wählen.',
      unexpected:
        'Es tut uns leid. Ein unerwarteter Fehler ist gerade aufgetreten.',
    },
    changeLocation: 'Wählen Sie einen anderen Ort',
    dateFull: '{{ date, full }}',
    dateDay: '{{ date, day }}',
    dateTime: '{{ date, time }}',
    timezone: '{{ date, timezone }}',
    interviewExpired:
      'Sie können dieses Interview nicht mehr verschieben oder absagen.',
  },
};

export default de;
