import cn from 'classnames';
import { Provider } from 'react-redux';
import store from '@redux/store';
import tagStyles from '@common/assets/styles/main.scss';

import i18n from './i18n/i18n';
import styles from './app.module.scss';
import Routes from './routes';
import { I18nextProvider } from 'react-i18next';

function App() {
  return (
    <div className={cn(tagStyles.tagDs, styles.app)}>
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <Routes />
        </Provider>
      </I18nextProvider>
    </div>
  );
}

export default App;
