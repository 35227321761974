import cn from 'classnames';

import styles from './styles.module.scss';

const Loader = ({ title, fullHeight, large }: any) => {
  return (
    <div
      className={cn(styles.loader, {
        [styles.fullheight]: fullHeight,
        [styles.large]: large,
      })}
    >
      <div className={styles.wrapper}>
        <div className={styles.spinner} />
        <div className={styles.title}>{title}</div>
      </div>
    </div>
  );
};

export default Loader;
