const it = {
  brands: {
    Adecco: 'Adecco',
    AdeccoFAQ: 'Adecco',
    AdeccoFull: 'Adecco',
    AdeccoGroup: 'The Adecco Group',
    AdeccoMedical: 'Adecco Medical',
    Amazon: 'Amazon',
    Akkodis: 'Akkodis',
    DISAG: 'DISAG',
    DISConsulting: 'DIS Consulting',
    EuropeAssistant: 'Europe Assistant',
    LHH: 'LHH',
    Modis: 'Modis',
    Pontoon: 'Pontoon',
    QAPA: 'QAPA',
  },
  candidateCapture: {
    intro:
      'Prima di iniziare la conversazione, ti preghiamo di condividere con noi i tuoi dati di contatto',
    email: 'Email',
    firstname: 'Nome',
    lastname: 'Cognome',
    countryCode: 'Selezionare il codice del paese',
    phone: 'Telefono',
    privacy:
      "Dichiaro di aver letto e accettato i <a href={{privacyUrl}} target='_blank'>l'Informativa sulla privacy</a>.",
    termsAndPrivacy:
      "Dichiaro di aver letto e accettato i <a href={{termsUrl}} target='_blank'>Termini di utilizzo</a> e <a href={{privacyUrl}} target='_blank'>l'Informativa sulla privacy</a>.",
    submit: 'Invia',
    validations: {
      emailRequired: "è richiesta l'email.",
      invalidEmail: 'Formato email non valido.',
      firstnameRequired: 'è richiesto il nome.',
      lastnameRequired: 'è richiesto il cognome.',
      phoneRequired: 'è richiesto il telefono.',
      invalidPhone:
        'Il numero di telefono deve iniziare con "+" e il prefisso internazionale, per es. +39xxxxxxxx.',
      termsAndCondRequired: 'Obbligatorio',
    },
  },
  common: {
    confirm: 'Conferma',
    return: 'Indietro',
    close: 'Chiudere',
    dateFormat: 'DD/MM/YYYY',
    submit: 'Invia',
    pleaseWait: 'Si prega di attendere mentre carichiamo Career Assistant',
    hello: 'Ciao a tutti 👋',
    letsChat: 'chiacchieriamo',
    later: 'più tardi',
    error: {
      title: 'Qualcosa non va!',
      subtitle:
        'Non siamo in grado di connetterci al servizio. I nostri team sono stati informati e stiamo lavorando per risolvere rapidamente il problema. Fare clic su Riprova per riprovare o tornare più tardi.',
      retry: 'Riprova',
    },
  },
  contact: {
    name: 'Nome',
    email: 'Email',
    description: 'Si prega di descrivere il problema',
    submit: 'Invia',
    validations: {
      required: 'Questo campo è obbligatorio.',
      invalidEmail: 'Email non valida.',
    },
    success: {
      title: 'Il vostro rapporto è stato inviato con successo.',
      subtitle: 'Ora è possibile chiudere questa finestra.',
    },
  },
  contactPreference: {
    subscribe: 'Registrati',
    unsubscribe: 'Disiscriviti',
    contactPreference: 'Preferenza di contatto',
    selectEmail: "Seleziona le sue preferenze per l'e-mail",
    selectSms: 'Selezionare la preferenza per SMS per il numero ',
    selectWhatsApp: 'Selezionare la preferenza per WhatsApp per il numero',
    warning:
      'Scegliendo "Disiscriversi", non sarà più possibile ricevere e-mail e/o SMS e/o messaggi WhatsApp da parte nostra.',
    warningEmail:
      'Scegliendo "Disiscriversi" non potrai più ricevere messaggi email da parte di CA Adecco (Career Assistant di Adecco).',
    warningPhone:
      'Scegliendo "Disiscriversi" non potrai più ricevere messaggi SMS da parte di CA Adecco (Career Assistant di Adecco).',
    confirmed: 'Grazie, le tue preferenze sono state prese in considerazione.',
    confirmedSubscribeEmail:
      'Grazie, la tua richiesta di registrazione via e-mail è stata confermata.',
    confirmedSubscribePhone:
      'Grazie, la tua richiesta di registrazione via SMS è stata confermata.',
    confirmedUnsubscribeEmail:
      'Grazie, la tua richiesta di cancellazione via e-mail è stata confermata.',
    confirmedUnsubscribePhone:
      'Grazie, la tua richiesta di disiscrizione via SMS è stata confermata.',
    invalidEmail: "L'email di conferma fornita non è corretta.",
    invalidPhone: 'Le ultime 4 cifre del cellulare non sono corrette.',
    invalidEmailOrPhone:
      "Il telefono o l'e-mail di conferma non sono corretti.",
    confirmEmail: 'Conferma il tuo indirizzo email: ',
    confirmPhone: 'Digita le ultime 4 cifre del tuo cellulare: ',
    confirm: 'Conferma',
    return: 'Indietro',
  },
  interview: {
    interviewIntroduction:
      'Il nostro assistente virtuale per il reclutamento, Career Assistant, ha alcune domande aggiuntive per pre-qualificarti per la posizione. Le domande richiedono meno di 5 minuti per essere completate e accelereranno il processo di assunzione, permettendoci di mettervi al lavoro il più rapidamente possibile! Dopo aver risposto alle domande, potrete fissare un appuntamento in filiale. Rispondete alla prima domanda qui sotto per iniziare!',
    passMessage1: 'Fantastico! Hai superato le domande di pre-qualificazione!',
    passMessage2: 'Passiamo alla fase successiva!',
    pleaseWait: 'Si prega di attendere, la fase successiva è in arrivo',
    changeInterviewSlot: 'Cambia lo slot del colloquio',
    rescheduleScheduled:
      'Il vostro colloquio in presenza è attualmente programmato per {{ location }}',
    chooseLocation:
      'Si prega di scegliere il luogo preferito per il colloquio in presenza',
    chooseTime: "Scegliete la data e l'ora del colloquio che preferite",
    select: 'Selezionare',
    interviewDuration: 'Durata del colloquio',
    noSlots: 'Purtroppo non ci sono slot disponibili per questa sede',
    rescheduled: 'Il colloquio è stato riprogrammato!',
    scheduled: 'Colloquio prenotato!',
    isRescheduled:
      'Il vostro nuovo colloquio in presenza è ora programmato per {{ location }}',
    isScheduled:
      'Il tuo colloquio in presenza è programmato per {{ location }}',
    yourInterviewDetails: 'Dettagli del colloquio',
    location: 'Posizione',
    dateAndTime: 'Data e ora',
    sendingConfirmation:
      "Ti invieremo un'e-mail di conferma. Se hai bisogno di riprogrammare o cancellare, utilizza le opzioni sottostanti o segui le istruzioni contenute nell'e-mail",
    cancelInterview: 'Annulla il colloquio',
    ratingThankYou:
      "Grazie per averci aiutato a offrire un'esperienza utente migliore",
    ratingBeforeYouGo:
      "Prima di andare via, puoi valutare l'esperienza di oggi?",
    ratingLeaveComment: 'Lascia un commento per favore',
    ratingLeaveCommentOptional: 'Lascia un commento per favore (facoltativo)',
    ratingComment: 'Il tuo commento va qui...',
    ratingSubmit: 'Invia feedback',
    cancelAsk:
      "Siamo spiacenti di sapere che vuoi annullare il tuo colloquio. Potresti invece prendere in considerazione l'idea di fare una nuova selezione?",
    interviewCancelled: 'Colloquio annullato',
    interviewCancelled1:
      "Il tuo colloquio è stato annullato. Ti verrà inviata a breve un'e-mail di conferma. Siamo spiacenti di vederti andare via",
    interviewCancelled2:
      'Ti invitiamo a visitarci nuovamente per cercare altre offerte di lavoro o prenotare un nuovo colloquio',
    errore: {
      slotTaken:
        'Siamo spiacenti. Lo slot selezionato è stato appena occupato! Prova a selezionare di nuovo.',
      unexpected: 'Siamo spiacenti. Si è appena verificato un errore inatteso.',
    },
    changeLocation: "Seleziona un'altra località",
    dateFull: '{{ date, full }}',
    dateDay: '{{ date, day }}',
    dateTime: '{{ date, time }}',
    timezone: '{{ date, timezone }}',
    interviewExpired:
      'Non è più possibile riprogrammare o annullare questo colloquio.',
  },
};

export default it;
