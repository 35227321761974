import { Component } from 'react';
import {
  AppInsightsContext,
  IAppInsightsContext,
} from '@common/utils/AppInsightsProvider';
import Header from '@common/Header';
import i18n from '@i18n/i18n';

import styles from './app.module.scss';

interface IErrorBoundaryProps {
  children: React.ReactElement;
}

interface IErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static contextType = AppInsightsContext;

  static getDerivedStateFromError(error: any) {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error) {
    (this.context as IAppInsightsContext).trackException(error);
    if (error.name === 'ChunkLoadError') {
      window.location.reload();
    } else {
      this.setState({ hasError: true });
    }
  }

  render() {
    const { hasError } = this.state;
    if (hasError) {
      return (
        <>
          <Header />
          <div className={styles.error}>
            <div className={styles.logo} />
            <h1>{i18n.t('common:error.title')}</h1>
            <h4>{i18n.t('common:error.subtitle')}</h4>
            <button onClick={() => window.location.reload()}>
              {i18n.t('common:error.retry')}
            </button>
          </div>
        </>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
