import { createInstance } from 'i18next';
import { initReactI18next } from 'react-i18next';

import de from './locales/de';
import en from './locales/en';
import enCA from './locales/en-ca';
import enUS from './locales/en-us';
import es from './locales/es';
import frCA from './locales/fr-ca';
import fr from './locales/fr';
import it from './locales/it';
import ja from './locales/ja';
import nl from './locales/nl';
import pl from './locales/pl';

const i18n = createInstance({
  fallbackLng: 'en',
  supportedLngs: [
    'de',
    'en',
    'en-CA',
    'en-US',
    'es',
    'fr',
    'fr-CA',
    'it',
    'ja',
    'nl',
    'pl',
  ],
  debug: false,
  keySeparator: '.',
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources: {
    de,
    en,
    'en-CA': enCA,
    'en-US': enUS,
    es,
    fr,
    'fr-CA': frCA,
    it,
    ja,
    nl,
    pl,
  },
});

i18n.use(initReactI18next).init();
i18n.changeLanguage(window.navigator.language);

export default i18n;
