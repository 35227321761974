const frCA = {
  brands: {
    Adecco: 'Adecco',
    AdeccoFAQ: 'Adecco',
    AdeccoFull: 'Adecco',
    AdeccoGroup: 'The Adecco Group',
    AdeccoMedical: 'Adecco Medical',
    Amazon: 'Amazon',
    Akkodis: 'Akkodis',
    DISAG: 'DISAG',
    DISConsulting: 'DIS Consulting',
    EuropeAssistant: 'Europe Assistant',
    LHH: 'LHH',
    Modis: 'Modis',
    Pontoon: 'Pontoon',
    QAPA: 'QAPA',
  },
  candidateCapture: {
    intro:
      "Avant d'entamer la conversation, veuillez nous communiquer vos coordonnées",
    email: 'Email',
    firstname: 'Prénom',
    lastname: 'Nom',
    countryCode: 'Sélectionnez le code du pays',
    phone: 'Téléphone',
    privacy:
      "Je reconnais avoir lu et j'accepte <a href={{privacyUrl}} target='_blank'>la politique de confidentialité</a>.",
    termsAndPrivacy:
      "Je reconnais avoir lu et j'accepte <a href={{termsUrl}} target='_blank'>les conditions d'utilisation</a> et <a href={{privacyUrl}} target='_blank'>la politique de confidentialité</a>.",
    submit: 'Envoyer',
    validations: {
      emailRequired: 'Votre email est obligatoire.',
      invalidEmail: "Format de l'email invalide",
      firstnameRequired: 'Votre prénom est obligatoire.',
      lastnameRequired: 'Votre nom est obligatoire.',
      phoneRequired: 'Votre téléphone est obligatoire.',
      invalidPhone:
        "Le numéro de téléphone doit commencer par '+' et votre code de pays (+33 679 000 000).",
      termsAndCondRequired: 'Obligatoire',
    },
  },
  common: {
    confirm: 'Confirmer',
    return: 'Retour',
    close: 'Fermer',
    dateFormat: 'JJ/MM/AAAA',
    submit: 'Soumettre',
    pleaseWait:
      'Veuillez patienter pendant que nous chargeons Career Assistant',
    hello: 'Bonjour 👋',
    letsChat: 'discutons-en',
    later: 'plus tard',
    error: {
      title: 'Il y a un problème !',
      subtitle:
        'Nous ne parvenons pas à nous connecter au service. Nos équipes ont été informées et nous travaillons à résoudre rapidement le problème. Cliquez sur Réessayer pour réessayer ou revenez plus tard.',
      retry: 'Réessayer',
    },
  },
  contact: {
    name: 'Nom',
    email: 'Email',
    description: 'Veuillez décrire le problème',
    submit: 'Soumettre',
    validations: {
      required: 'Ce champ est obligatoire.',
      invalidEmail: 'Email non valide.',
    },
    success: {
      title: 'Votre rapport a été soumis avec succès.',
      subtitle: 'Vous pouvez maintenant fermer cette fenêtre.',
    },
  },
  contactPreference: {
    subscribe: "S'inscrire",
    unsubscribe: 'Se désinscrire',
    contactPreference: 'Préférence de contact',
    selectEmail: "Sélectionnez vos préférences pour l'email",
    selectSms: 'Veuillez sélectionner votre préférence pour SMS pour le numéro',
    selectWhatsApp:
      'Veuillez sélectionner votre préférence pour WhatsApp pour le numéro',
    warning:
      'En choisissant de vous "Se désinscrire", vous ne pourrez plus recevoir d\'e-mails et/ou de SMS et/ou de messages WhatsApp de notre part.',
    warningEmail:
      "En cliquant sur \"Se désinscrire\", vous ne recevrez plus d'emails de la part de Career Assistant fournis par le groupe Adecco France. Mais vous serez susceptible d'en recevoir via d'autres services.",
    warningPhone:
      'En choisissant "Se désinscrire", vous ne pourrez plus recevoir de SMS de notre part.',
    confirmed: 'Merci, vos choix ont été pris en compte.',
    confirmedSubscribeEmail:
      "Merci, votre demande d'inscription par email a bien été validée.",
    confirmedSubscribePhone:
      "Merci, votre demande d'inscription par SMS a bien été validée.",
    confirmedUnsubscribeEmail:
      'Merci, votre demande de désinscription par email a bien été validée.',
    confirmedUnsubscribePhone:
      'Merci, votre demande de désinscription par SMS a bien été validée.',
    invalidEmail: "L'e-mail de confirmation est incorrect.",
    invalidPhone:
      'Les 4 derniers chiffres du téléphone portable ne sont pas corrects.',
    invalidEmailOrPhone:
      'La confirmation par téléphone ou par e-mail était incorrecte.',
    confirmEmail: 'Merci de confirmer votre adresse e-mail: ',
    confirmPhone:
      'Veuillez saisir les 4 derniers chiffres de votre téléphone portable: ',
    confirm: 'Confirmer',
    return: 'Retour',
  },
  interview: {
    interviewIntroduction:
      "Notre assistant virtuel de recrutement, Career Assistant, a quelques questions supplémentaires à vous poser pour vous qualifier pour le poste. Les questions prennent moins de 5 minutes et accélèrent le processus d'embauche, ce qui nous permet de vous trouver un poste le plus rapidement possible ! Une fois que vous aurez répondu aux questions, vous pourrez prendre rendez-vous avec l'agemce. Répondez à la première question ci-dessous pour commencer !",
    passMessage1:
      'Fantastique ! Vous avez répondu aux questions de pré-qualification !',
    passMessage2: "Passons à l'étape suivante !",
    pleaseWait: "Veuillez patienter, l'étape suivante arrive",
    changeInterviewSlot: "Changez de créneau d'entretien",
    rescheduleScheduled:
      'Votre entretien sur place est actuellement programmé pour {{ location }}',
    chooseLocation:
      "Veuillez choisir le lieu de l'entretien sur place que vous préférez",
    chooseTime:
      "Veuillez choisir la date et l'heure de l'entretien que vous préférez",
    select: 'Sélectionner',
    interviewDuration: "Durée de l'entretien",
    noSlots:
      "Malheureusement, il n'y a pas de créneaux disponibles pour ce lieu",
    rescheduled: "Vous avez reporté l'entretien !",
    scheduled: 'Vous êtes programmé !',
    isRescheduled:
      'Votre nouvel entretien sur place est désormais programmé pour {{ location }}.',
    isScheduled: 'Votre entretien sur place est programmé pour {{ location }}.',
    yourInterviewDetails: 'Détails de votre entretien',
    location: 'Lieu',
    dateAndTime: 'Date et heure',
    sendingConfirmation:
      "Nous vous enverrons un courriel de confirmation. Si vous avez besoin de reprogrammer ou d'annuler, veuillez utiliser les options ci-dessous ou suivre les instructions figurant dans votre courrier électronique.",
    cancelInterview: "Annuler l'entretien",
    ratingThankYou:
      "Merci de nous aider à améliorer l'expérience des utilisateurs",
    ratingBeforeYouGo:
      "Avant de partir, pouvez-vous évaluer l'expérience d'aujourd'hui ?",
    ratingLeaveComment: 'Laissez-nous un commentaire',
    ratingLeaveCommentOptional: 'Laissez-nous un commentaire (facultatif)',
    ratingComment: 'Votre commentaire va ici...',
    ratingSubmit: 'Envoyer un commentaire',
    cancelAsk:
      "Nous sommes désolés d'apprendre que vous souhaitez annuler votre entretien. Pourriez-vous envisager de faire une nouvelle sélection à la place ?",
    interviewCancelled: 'Entretien annulé',
    interviewCancelled1:
      'Votre entretien a été annulé. Un e-mail de confirmation vous sera envoyé dans les plus brefs délais. Nous sommes désolés de vous voir partir',
    interviewCancelled2:
      "Veuillez nous rendre visite à nouveau pour rechercher d'autres emplois ou réserver un nouvel entretien.",
    error: {
      slotTaken:
        "Nous sommes désolés. Le créneau que vous avez sélectionné vient d'être pris ! Veuillez réessayer de le sélectionner",
      unexpected:
        'Nous sommes désolés. Une erreur inattendue vient de se produire.',
    },
    changeLocation: 'Sélectionnez un autre lieu',
    dateFull: '{{ date, full }}',
    dateDay: '{{ date, day }}',
    dateTime: '{{ date, time }}',
    timezone: '{{ date, timezone }}',
    interviewExpired: 'Vous ne pouvez plus reporter ou annuler cet entretien.',
  },
};

export default frCA;
