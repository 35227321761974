import { DirectLineModel } from '@adeccoca/cxbot-core';
import {
  AvailableInterviewSlots,
  InterviewLocation,
  CandidateCaptureModel,
  TrackJobLinkClickRequest,
  SelectedInterviewSlot,
  NotifyIssueRequest,
  GetContactPreferencesResponse,
  ConfirmCalendarSlotRequest,
  ConversationRatingRequest,
  CancelCalendarSlotRequest,
} from '@appTypes/conversation.types';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = (window as any)?.CACxBotOptions?.url || window.location.origin;

const conversationApi = createApi({
  reducerPath: 'ontology',
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: builder => ({
    getBotToken: builder.query<DirectLineModel | CandidateCaptureModel, string>(
      {
        query: (shortener: string) => `/api/home/${shortener}`,
      }
    ),
    captureCandidate: builder.mutation<
      { code: string; success: boolean },
      FormData
    >({
      query: (formData: FormData) => ({
        url: `/api/CandidateCapture/Create`,
        method: 'POST',
        body: formData,
      }),
    }),
    registerClick: builder.mutation<void, FormData>({
      query: (formData: FormData) => ({
        url: `/api/CandidateCapture/RegisterClick`,
        method: 'POST',
        body: formData,
      }),
    }),
    trackJobLinkClick: builder.mutation<void, TrackJobLinkClickRequest>({
      query: body => ({
        url: `/api/tracking/job-link-click`,
        method: 'POST',
        body,
      }),
    }),
    getSelectedInterviewSlot: builder.query<SelectedInterviewSlot, string>({
      query: (shortener: string) => `/api/Calendar/slot/candidate/${shortener}`,
    }),
    getInterviewLocations: builder.query<InterviewLocation[], string>({
      query: (shortener: string) =>
        `/api/Calendar/location?entityId=${shortener}`,
    }),
    getAvailableInterviewSlots: builder.query<
      AvailableInterviewSlots,
      {
        calendarId: string;
        shortener: string;
        fromDate: number;
        toDate: number;
      }
    >({
      query: ({ calendarId, shortener, fromDate, toDate }) =>
        `/api/Calendar/${calendarId}/slot?entityId=${shortener}&from=${fromDate}&to=${toDate}`,
    }),
    confirmCalendarSlot: builder.mutation<any, ConfirmCalendarSlotRequest>({
      query: body => ({
        url: `api/Calendar/slot`,
        method: 'POST',
        body,
      }),
    }),
    cancelCalendarSlot: builder.mutation<void, CancelCalendarSlotRequest>({
      query: body => ({
        url: `/api/Calendar/slot`,
        method: 'DELETE',
        body,
      }),
    }),
    notifyIssue: builder.mutation<void, NotifyIssueRequest>({
      query: body => ({
        url: `/api/contact-form/submit`,
        method: 'POST',
        body,
      }),
    }),
    getContactPreferences: builder.query<
      GetContactPreferencesResponse,
      { type: string; shortener: string }
    >({
      query: ({ type, shortener }) => 
        type === 's'
      ? `/api/contactPreference/stop/${type}/${shortener}`
      : `/api/contactPreference/${type}/${shortener}`,
    }),
    confirmContactPreferences: builder.mutation<
      GetContactPreferencesResponse,
      { shortener: string; formData: FormData }
    >({
      query: ({ shortener, formData }) => ({
        url: `/api/contactPreference/confirm/${shortener}`,
        method: 'POST',
        body: formData,
      }),
    }),
    sendConversationRating: builder.mutation<null, ConversationRatingRequest>({
      query: (body: ConversationRatingRequest) => ({
        url: `/api/ConversationRating`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useGetBotTokenQuery,
  useLazyGetBotTokenQuery,
  useCaptureCandidateMutation,
  useRegisterClickMutation,
  useTrackJobLinkClickMutation,
  useGetSelectedInterviewSlotQuery,
  useLazyGetSelectedInterviewSlotQuery,
  useGetInterviewLocationsQuery,
  useLazyGetAvailableInterviewSlotsQuery,
  useConfirmCalendarSlotMutation,
  useCancelCalendarSlotMutation,
  useNotifyIssueMutation,
  useLazyGetContactPreferencesQuery,
  useConfirmContactPreferencesMutation,
  useSendConversationRatingMutation,
} = conversationApi;
export default conversationApi;
