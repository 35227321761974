import React, { Suspense } from 'react';
import {
  BrowserRouter,
  MemoryRouter,
  Routes as Switch,
  Route,
  Navigate,
} from 'react-router-dom';
import Loader from '@common/Loader';
import { DirectLineContextProvider } from '@common/utils/DirectLineProvider';
import { AppInsightsContextProvider } from '@common/utils/AppInsightsProvider';
import ErrorBoundary from './ErrorBoundary';

const Conversation = React.lazy(() => import('@components/Conversation'));
const CandidateCapture = React.lazy(
  () => import('@components/CandidateCapture')
);
const InterviewCalendar = React.lazy(
  () => import('@components/InterviewCalendar')
);
const ContactForm = React.lazy(() => import('@components/ContactForm'));
const ContactPreference = React.lazy(
  () => import('@components/ContactPreference')
);

const { isEmbed, id } = (window as any)?.CACxBotOptions || {};

const withProviders = (children: React.ReactElement) => (
  <DirectLineContextProvider>
    <AppInsightsContextProvider>
      <ErrorBoundary>{children}</ErrorBoundary>
    </AppInsightsContextProvider>
  </DirectLineContextProvider>
);

const withRouter = (children: React.ReactElement) =>
  isEmbed ? (
    <MemoryRouter>{children}</MemoryRouter>
  ) : (
    <BrowserRouter>{children}</BrowserRouter>
  );

const Routes = () => {
  const LoaderComponent = isEmbed ? <></> : <Loader fullHeight />;

  const withSuspense = (Component: React.ReactElement) => (
    <Suspense fallback={LoaderComponent}>{Component}</Suspense>
  );

  return withRouter(
    <Switch>
      <Route
        path="/:shortener"
        element={withSuspense(withProviders(<Conversation />))}
      />
      <Route
        path="/candidate/:shortener"
        element={withSuspense(withProviders(<Conversation />))}
        //WARNING: Entry point used by SWP integration - DO NOT REMOVE
      />
      <Route
        path="/candidateCapture/:shortener"
        element={withSuspense(withProviders(<CandidateCapture />))}
      />
      <Route
        path="/calendar/:shortener"
        element={withSuspense(withProviders(<InterviewCalendar />))}
      />
      <Route
        path="/contact-form/:trackingId"
        element={withSuspense(<ContactForm />)}
      />
      <Route
        path="/stop/:contactPreference/:shortener"
        element={withSuspense(withProviders(<ContactPreference />))}
      />
      <Route
        path="/:contactPreference/:shortener"
        element={withSuspense(withProviders(<ContactPreference />))}
      />
      {!isEmbed && <Route path="*" element={<Navigate to="/404" replace />} />}
      {isEmbed && (
        <Route path="*" element={<Navigate to={`/${id}`} replace />} />
      )}
    </Switch>
  );
};

export default Routes;
