export const retrieveFromLocalStorage = (key: string): string | undefined => {
  try {
    return localStorage.getItem(key);
  } catch {
    return undefined;
  }
};

export const addToLocalStorage = (
  key: string,
  value: string | undefined
): void => {
  try {
    if (typeof value === 'undefined') {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, value);
    }
  } catch {}
};
