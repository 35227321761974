import React, { useContext, useEffect, useMemo } from 'react';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from 'history';

import { useDirectLineContext } from './DirectLineProvider';

export interface IAppInsightsContext {
  trackPageView: (name: string) => void;
  trackEvent: (name: string) => void;
  trackException: (error: Error) => void;
}

let appInsights: ApplicationInsights = null;

export const AppInsightsContext = React.createContext<
  IAppInsightsContext | undefined
>(undefined);

export const AppInsightsContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const history = createBrowserHistory();
  const reactPlugin = new ReactPlugin();
  const { model } = useDirectLineContext();
  const { appInsightsInstrumentationKey } = model || {};

  if (appInsightsInstrumentationKey) {
    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: appInsightsInstrumentationKey,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: {
            history,
          },
        },
        disableExceptionTracking: true,
      },
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView();
  }

  const value = useMemo(
    () => ({
      trackPageView: (name: string) => appInsights?.trackPageView({ name }),
      trackEvent: (name: string) => appInsights?.trackEvent({ name }),
      trackException: (error: Error) => appInsights?.trackException({ error }),
    }),
    []
  );

  return (
    <AppInsightsContext.Provider value={value}>
      {children}
    </AppInsightsContext.Provider>
  );
};

export const useAppInsights = () => {
  const appInsightsContext = useContext(AppInsightsContext);
  if (appInsightsContext === undefined) {
    throw new Error(
      'AppInsightsContext must be used inside AppInsightsContextProvider'
    );
  }

  return appInsightsContext;
};

export default appInsights;
