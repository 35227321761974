const enUS = {
  brands: {
    Adecco: 'Adecco',
    AdeccoFAQ: 'Adecco',
    AdeccoFull: 'Adecco',
    AdeccoGroup: 'The Adecco Group',
    AdeccoMedical: 'Adecco Medical',
    Amazon: 'Amazon',
    Akkodis: 'Akkodis',
    DISAG: 'DISAG',
    DISConsulting: 'DIS Consulting',
    EuropeAssistant: 'Europe Assistant',
    LHH: 'LHH',
    Modis: 'Modis',
    Pontoon: 'Pontoon',
    QAPA: 'QAPA',
  },
  candidateCapture: {
    intro:
      'Before starting the conversation, please share with us your contact details.',
    email: 'Email',
    firstname: 'First name',
    lastname: 'Last name',
    phone: 'Phone number',
    countryCode: 'Select country code',
    privacy:
      "I acknowledge that I have read and accepted the <a href={{privacyUrl}} target='_blank'>Privacy Policy</a>.",
    termsAndPrivacy:
      "I acknowledge that I have read and accepted the <a href={{termsUrl}} target='_blank'>Terms of Use</a> and the <a href={{privacyUrl}} target='_blank'>Privacy Policy</a>.",
    submit: 'Submit',
    validations: {
      emailRequired: 'E-mail is required.',
      invalidEmail: 'E-mail format is not valid',
      firstnameRequired: 'First name is required.',
      lastnameRequired: 'Last name is required.',
      phoneRequired: 'Phone number is required.',
      invalidPhone:
        'Phone number should start with "+" and your country code, e.g. +44xxxxxxxx',
      termsAndCondRequired: 'Required',
    },
  },
  common: {
    confirm: 'Confirm',
    return: 'Return',
    close: 'Close',
    dateFormat: 'DD/MM/YYYY',
    submit: 'Submit',
    pleaseWait: 'Please wait while we load Career Assistant',
    hello: 'Hi there 👋',
    letsChat: "let's chat",
    later: 'later',
    error: {
      title: "Something's wrong!",
      subtitle:
        'We are unable to connect to the service. Our teams have been notified and we are working to quickly fix the issue. Click Retry to try again or come back later.',
      retry: 'Retry',
    },
  },
  contact: {
    name: 'Name',
    email: 'Email',
    description: 'Please describe the issue',
    submit: 'Submit',
    validations: {
      required: 'This field is required.',
      invalidEmail: 'Invalid email.',
    },
    success: {
      title: 'Your report has been successfully submitted.',
      subtitle: 'You can now close this window.',
    },
  },
  contactPreference: {
    subscribe: 'Subscribe',
    unsubscribe: 'Unsubscribe',
    contactPreference: 'Contact preference',
    selectEmail: 'Please select your email preference',
    selectSms: 'Please select your preference for SMS for the number',
    selectWhatsApp: 'Please select your preference for WhatsApp for the number',
    warning:
      'By choosing "Unsubscribe", you will no longer be able to receive emails and/or SMS and/or WhatsApp messages from us.',
    warningEmail:
      'By choosing "Unsubscribe", you will no longer be able to receive emails from us.',
    warningPhone:
      'By choosing "Unsubscribe", you will no longer be able to receive SMS messages from us.',
    confirmed: 'Thank you, your choices have been taken into account.',
    confirmedSubscribeEmail:
      'Thank you, your registration request by email has been validated.',
    confirmedSubscribePhone:
      'Thank you, your registration request by SMS has been validated.',
    confirmedUnsubscribeEmail:
      'Thank you, your request to unsubscribe by email has been validated.',
    confirmedUnsubscribePhone:
      'Thank you, your request to unsubscribe by SMS has been validated.',
    confirmEmail: 'Please confirm your E-mail address: ',
    confirmPhone: 'Please type in the last 4 digits of your phone number: ',
    invalidEmail: "The confirmation E-mail provided it's not correct.",
    invalidPhone: 'The last 4 digits of the phone number are not correct.',
    invalidEmailOrPhone: 'The phone or E-mail provided was incorrect.',
    validations: {
      invalidEmail: 'E-mail format is not valid',
      emailRequired: 'E-mail is required.',
      phoneRequired: 'Phone number is required.',
    },
    confirm: 'Confirm',
    return: 'Return',
  },
  interview: {
    interviewIntroduction:
      'Our virtual recruiting assistant, Career Assistant, has a few additional questions to pre-qualify you for the position. The questions take under 5 minutes to complete and will speed up the hiring process, allowing us to get you to work as quickly as possible! After you answer the questions, you will be able to go ahead and schedule a branch appointment. Respond to the first question below to get started!',
    passMessage1: 'Fantastic! You have passed the pre-qualifying questions!',
    passMessage2: "Let's move you to the next step!",
    pleaseWait: 'Please wait, the next step is coming',
    changeInterviewSlot: 'Change interview slot',
    rescheduleScheduled:
      'Your on-site interview is currently scheduled for {{ location }}.',
    chooseLocation: 'Please choose your preferred interview on-site location.',
    chooseTime: 'Please choose your preferred interview date and time.',
    select: 'Select',
    interviewDuration: 'Interview duration',
    noSlots: 'Unfortunately, there are no slots available for this location.',
    rescheduled: 'You have rescheduled!',
    scheduled: "You're scheduled!",
    isRescheduled:
      'Your new on-site interview is now scheduled for {{ location }}.',
    isScheduled: 'Your on-site interview is scheduled for {{ location }}.',
    yourInterviewDetails: 'Your Interview Details',
    location: 'Location',
    dateAndTime: 'Date and Time',
    sendingConfirmation:
      'We will be sending you an email confirmation. If you need to reschedule or cancel, please use the options below or follow the instructions in your email.',
    cancelInterview: 'Cancel interview',
    ratingThankYou:
      'Thank you for helping us deliver a better user experience.',
    ratingBeforeYouGo: "Before you go, can you rate today's experience?",
    ratingLeaveComment: 'Leave us a comment',
    ratingLeaveCommentOptional: 'Leave us a comment (optional)',
    ratingComment: 'Your comment goes here...',
    ratingSubmit: 'Send feedback',
    cancelAsk:
      "We're sorry to hear that you want to cancel your interview. Would you consider making a new selection instead?",
    interviewCancelled: 'Interview cancelled',
    interviewCancelled1:
      'Your interview has been cancelled. An e-mail confirmation will be sent to you shortly. We are sorry to see you go.',
    interviewCancelled2:
      'Please visit us again to search for more jobs or book a new interview.',
    error: {
      slotTaken:
        "We're sorry. Your selected slot was just taken! Please try selecting again.",
      unexpected: "We're sorry. An unexpected error just occured.",
    },
    changeLocation: 'Select another location',
    dateFull: '{{ date, full }}',
    dateDay: '{{ date, day }}',
    dateTime: '{{ date, time }}',
    timezone: '{{ date, timezone }}',
    interviewExpired: 'You can no longer reschedule or cancel this interview.',
  },
};

export default enUS;
