import cn from 'classnames';
import brands from '@common/brands.module.scss';
import { useDirectLineContext } from '@common/utils/DirectLineProvider';

import styles from './styles.module.scss';

interface IHeaderProps {
  children?: React.ReactElement;
  isFixed?: boolean;
}

const Header: React.FC<IHeaderProps> = ({ children, isFixed }) => {
  const { model } = useDirectLineContext();
  return (
    <div className={brands[model?.brand?.toLowerCase()]}>
      <header className={cn(styles.header, { [styles.isFixed]: isFixed })}>
        <div className={styles.banner} id="banner">
          <div className={styles.logo}></div>
          <div className={styles.title}>Career Assistant</div>
        </div>
        {children}
      </header>
    </div>
  );
};

export default Header;
